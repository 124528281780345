let _window = $(window);
let _document = $(document);

$(document).ready(function () {

    function pageReady() {
        // вспомогательные скрипты, библиотеки
        legacySupport();
        imgToSvg();
        formSend();
        // activeHeaderScroll();
        // инициализация библиотек
        initSliders();
        initPopups();
        initMasks();
        // initSelectric();
        initLazyPicture();
        // кастомные скрипты
        burgerMenu();
        scrollTop();
        sectionNavigation();
        // headerHeightFun();
        // vhModule();
        // inputNumber();
    }

    pageReady();

    window.addEventListener('resize', () => {
        // headerHeightFun();
    })

    $(document).ready(function () {
        let open = false;
        $(".header__burger ").click(function () {
            if (!open) {
                $(".mobile__menu__wrapper  ").show('slow').css("display", "flex")
                // open = true;
                $(document).mouseup(function (e) {
                    var container = $(".mobile__menu__wrapper  ");
                    if (container.has(e.target).length === 0) {
                        container.hide('.mobile__menu__wrapper  ');
                    }
                });
            } else {
                $(".mobile__menu__wrapper  ").hide('slow');
                open = false;
            }
        });
        $('.popup__close ').on('click', function () {
            $(".mobile__menu__wrapper  ").hide('slow');
        });
    });

});