function initSliders() {
    new Swiper('.services__slider__container', {
        slidesPerView: 2,
        speed: 400,
        spaceBetween: 29,
        // noSwiping,
        loop: true,
        navigation: {
            nextEl: '.services__button-next',
            prevEl: '.services__button-prev',
        },
        breakpoints: {
            // 1200: {
            //   slidesPerView: 3,
            // },
            // 992: {
            //   slidesPerView: 2,
            // },
            768: {
              slidesPerView: 2,
            },
            568: {
                slidesPerView: 1,
            },
            320: {
                slidesPerView: 1,
            }
        }
    });

    new Swiper('.projects__slider__container', {
        slidesPerView: 4,
        speed: 400,
        spaceBetween: 20,
        // noSwiping,
        loop: true,
        navigation: {
            nextEl: '.projects__button-next',
            prevEl: '.projects__button-prev',
        },
        breakpoints: {
            1200: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            768: {
              slidesPerView: 2,
            },
            568: {
                slidesPerView: 1,
            },
            320: {
                slidesPerView: 1,
            }
        }
    });

    new Swiper('.clients__slider__container', {
        slidesPerView: 8,
        speed: 400,
        spaceBetween: 20,
        // noSwiping,
        loop: true,
        navigation: {
            nextEl: '.clients__button-next',
            prevEl: '.clients__button-prev',
        },
        breakpoints: {
            1201: {
              slidesPerView: 8,
            },
            992: {
              slidesPerView: 6,
            },
            768: {
              slidesPerView: 4,
            },
            568: {
                slidesPerView: 3,
            },
            320: {
                slidesPerView: 2,
            }
        }
    });

    
    new Swiper('.products__slider__container', {
        slidesPerView: 2,
        speed: 400,
        spaceBetween: 20,
        // noSwiping,
        loop: true,
        navigation: {
            nextEl: '.products__button-next',
            prevEl: '.products__button-prev',
        },
        breakpoints: {
            1201: {
              slidesPerView: 2,
            },
            320: {
                slidesPerView: 1,
            }
        }
    });

    new Swiper('.ecommerce__slider__container', {
        slidesPerView: 1,
        speed: 400,
        spaceBetween: 20,
        // noSwiping,
        loop: true,
        navigation: {
            nextEl: '.ecommerce__button-next',
            prevEl: '.ecommerce__button-prev',
        },

    });

    new Swiper('.team__slider__container', {
        slidesPerView: 4,
        speed: 400,
        spaceBetween: 20,
        // noSwiping,
        loop: true,
        navigation: {
            nextEl: '.team__button-next',
            prevEl: '.team__button-prev',
        },
        breakpoints: {
            1200: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            768: {
              slidesPerView: 2,
            },
            568: {
                slidesPerView: 1,
            },
            320: {
                slidesPerView: 1,
            }
        }
    });

    new Swiper('.awards__slider__container', {
        slidesPerView: 4,
        speed: 400,
        spaceBetween: 20,
        // noSwiping,
        loop: true,
        navigation: {
            nextEl: '.awards__button-next',
            prevEl: '.awards__button-prev',
        },
        breakpoints: {
            1200: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            768: {
              slidesPerView: 2,
            },
            568: {
                slidesPerView: 1,
            },
            320: {
                slidesPerView: 1,
            }
        }
    });
    
}